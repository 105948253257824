import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Col, Row } from 'react-grid-system';

import CallToAction
                         from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Spacer
                         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper
                         from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander
                         from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';


const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;


function IndexPage() {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Holtermann</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Holtermann, Ihrem exklusiven Juwelier im historischen Herzen von Krefeld Uerdingen. In unserer Meisterwerkstatt, geführt von erfahrenen Uhrmachermeistern, bieten wir Ihnen eine exquisite Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen, die mit handwerklicher Präzision und Liebe zum Detail gefertigt werden.</p>
            <p>Unser Juweliergeschäft in Krefeld Uerdingen zeichnet sich durch eine jahrzehntelange Tradition und Leidenschaft für feine Uhren und edlen Schmuck aus. Ob Sie auf der Suche nach dem perfekten Antragsring sind, einzigartige Eheringe wünschen oder ein individuelles Schmuckstück kreieren möchten – bei Juwelier Holtermann finden Sie einzigartige Schätze, die Ihre persönlichen Momente unvergesslich machen.</p>
            <p>Wir sind stolz darauf, Ihnen nicht nur Produkte höchster Qualität anzubieten, sondern auch einen umfassenden Service. Von professionellen Reparaturen in unserer Meisterwerkstatt bis hin zum Altgoldankauf, bei dem Sie Ihre alten Schätze in neue Kreationen verwandeln können, steht unser erfahrenes Team Ihnen mit Rat und Tat zur Seite.</p>
            <p>Entdecken Sie bei uns die Welt der feinen Uhren und des exquisiten Schmucks, in der Tradition und Innovation aufeinandertreffen. Lassen Sie sich von der Vielfalt unserer Kollektionen inspirieren und von der Expertise unserer Uhrmachermeister und Schmuckexperten beraten. Wir freuen uns darauf, Sie bei Juwelier Holtermann in Krefeld Uerdingen begrüßen zu dürfen.</p>
            <p>Bis bald in Uerdingen, Ihre Familie Lohmar mit Team</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unsere Markenwelt</Heading>
          <Display/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich.<br/>
            Neuwertermittlung: Bewertung von Schmuckstücken und Aufarbeitung
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Großuhren</h3>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Altgoldankauf</h3>
                  <List>
                    <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                    <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken.</li>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen.</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
